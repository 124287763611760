@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #1B1B1B;
  overflow-x: hidden;
}
html{
  scroll-behavior:smooth
  }